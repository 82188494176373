<template>
<div class="m-module">
    <div class="section">
        <div class="page-title">
            {{ $t("lang.Transaction") }}
        </div>
        <div class="body">
            <div class="filter-container">
               <van-dropdown-menu :close-on-click-outside="false">
                    <van-dropdown-item @change ="changeDro" v-model="coinType" :options="filterOption" />
                    <!-- <van-dropdown-item @change ="changeType" v-model="queryParam.serialType" :options="tradeOption" /> -->
                    <!-- <van-dropdown-item title="筛选" ref="item">
                        <van-cell class="filter-form-item" center title="交易开始时间">
                            <template #right-icon>
                                <van-field v-model="queryParam.createTimeStart" placeholder="Please select datetime" :readonly="true" @click="showDatePicker(0)" />
                            </template>
                        </van-cell>
                        <van-cell class="filter-form-item" center title="交易结束时间">
                            <template #right-icon>
                                <van-field v-model="queryParam.createTimeEnd" placeholder="Please select datetime" :readonly="true" @click="showDatePicker(1)" />
                            </template>
                        </van-cell>
                        <div style="padding: 5px 16px;">
                            <van-button type="primary" block round>
                                确认
                            </van-button>
                        </div>
                    </van-dropdown-item> -->
                </van-dropdown-menu> 
            </div>
            <div   class="table-inner"   element-loading-spinner="el-icon-loading" >
                <el-table :data="page"    >
                    <el-table-column width="130" :label="$t('lang.source')" prop="bizDescrVal"></el-table-column>
                    <el-table-column width="130" :label="$t('lang.GameID')" prop="gameId"></el-table-column>
                    <el-table-column width="130" :label="$t('lang.GameTitle')" prop="gameTitle">
                        <template slot-scope="scope">
                            <span @click="goOption(scope.row.gameId)" >{{ scope.row.gameTitle }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="130" :label="$t('lang.typeTransaction')" prop="serialType">
                        <template slot-scope="scope">
                            <span >{{ scope.row.serialTypeVal }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column width="130" :label="$t('lang.amountTransaction')" prop="revoCion"></el-table-column>
                    <el-table-column width="130" :label="$t('lang.CreationTime')" prop="createTime"></el-table-column>
                </el-table>
                <!-- <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="ipagination.pageNo"
                        :page-size="ipagination.pageSize"
                        layout="prev, pager, next, jumper"
                        :total="ipagination.total">
                    </el-pagination>
                </div> -->
            </div>
            <!-- <div class="list-wrapper">
                <div class="tab">
                    <div>{{ $t("lang.GameID") }}</div>
                    <div>{{ $t("lang.GameTitle") }}</div>
                    <div>{{ $t("lang.typeTransaction") }}</div>
                    <div>{{ $t("lang.amountTransaction") }}</div>
                </div>
                <van-list v-for="item in page"  :key="item.id">
                    <van-cell>
                        <div class="item">
                            <div class="item-hd">
                                <div class="item-val">{{ item.gameId  }}</div>
                                <div class="item-val" @click="goOption(item.gameId)">{{ item.gameTitle  }}</div>
                                <div class="item-val">{{ item.serialTypeVal  }}</div>
                                <div class="item-val">{{ item.revoCion  }}</div>
                            </div>
                        </div>
                    </van-cell>
                </van-list>
            </div> -->
            <van-pagination v-model="current" :total-items="dataList.total" :items-per-page="dataList.size" @change="changeTab">
                <template #prev-text>
                <van-icon name="arrow-left" />
                </template>
                <template #next-text>
                <van-icon name="arrow" />
                </template>
                <!-- <template #page="{ text }">{{ text }}</template> -->
            </van-pagination>
        </div>
        
    </div>
    <van-popup v-model="coinPopup" position="bottom" closeable :style="{ height: '50%' }">
        <div class="popup-mod">
            <div class="hd">
                <h3 class="title">Select Coin</h3>
            </div>
            <van-divider />
            <div class="bd">
                <ul class="coins">
                    <li :class="{'active':item.active}" v-for="(item, index) in coinsDataSource" :key="'coin_'+index"
                        @click="selectCoin(item, index)"
                    >
                        <div class="coin">
                            <div class="item-icon">
                                <img class="img" :src="item.icon" />
                            </div>
                            <div class="item-info">
                                <div class="item-title">{{ item.title }}</div>
                                <div class="item-desc">{{ item.desc }}</div>
                            </div>
                            <div class="item-status">
                                <i class="icon el-icon-check"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="ft">
                <div class="form-action">
                    <van-button round block type="primary" @click="selectCoinSubmit">提交</van-button>
                </div>
            </div>
        </div>
    </van-popup>
    <van-popup v-model="networkPopup" position="bottom" closeable :style="{ height: '50%' }">
        <div class="popup-mod">
            <div class="hd">
                <h3 class="title">Select Network</h3>
            </div>
            <van-divider />
            <div class="bd">
                <van-notice-bar wrapable left-icon="info-o">
                    Please note that only supported networks on Revolution platform are shown. If you deposit via another network your assets may be lost.
                </van-notice-bar>
                <ul class="networks">
                    <li class="active">
                        <div class="item">
                            <div class="item-info">
                                <div class="item-title">TRX</div>
                                <div class="item-desc">Tron(TRC20)</div>
                            </div>
                            <div class="item-status">
                                <i class="icon el-icon-check"></i>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item">
                            <div class="item-info">
                                <div class="item-title">TRX</div>
                                <div class="item-desc">Tron(TRC20)</div>
                            </div>
                            <div class="item-status">
                                <i class="icon el-icon-check"></i>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="ft">
                <div class="form-action">
                    <van-button round block type="primary">提交</van-button>
                </div>
            </div>
        </div>
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
            type="datetime"
            @cancel="showPicker = false"
            @confirm="confirmDatetimePicker"
        />
    </van-popup>
</div>
</template>
<script>
import moment from "moment";
import Header from "@/components/mobile/header";
import lineChart from "@/views/account/modules/lineChart.vue";
import { Toast } from 'vant';
import {BASE_GLOB_API} from "@/config.js";
import {axiosGet,axiosPost} from '@/api/api';
import EventBus from '@/utils/EventBus'
export default {
    name:'transaction-m',
    components: {
        Header,
        lineChart,
    },
    data(){
        return {
            coinPopup:false,
            networkPopup:false,
            showPicker:false,
            typePicker:0,
            form:{
                verifyCode:''
            },
            stepActive:0,
            queryParam:{
                serialType:0,
                createTimeStart:'',
                createTimeEnd:'',
            },
            coinType:0,
            filterOption:[
                // {text:'币种', value:0}, {text:'USDT', value:1},{text:'BTC', value:2},{text:'ETC', value:3}
            ],
            tradeOption:[
                // {text:'交易类型', value:0},{text:'充值', value:1},{text:'提现', value:2},{text:'转入', value:3},{text:'转出', value:4}
            ],
            coinsDataSource:[
                {id:1, title:'USDT', desc:'USDT', icon:require('@/assets/img/coins/USDT.svg'), active:false},
                {id:2, title:'BTC', desc:'BTC', icon:require('@/assets/img/coins/BTC.svg'), active:false},
                {id:3, title:'ETC', desc:'ETC', icon:require('@/assets/img/coins/ETC.svg'), active:false},
                {id:4, title:'ETH', desc:'ETH', icon:require('@/assets/img/coins/ETH.svg'), active:false},
                {id:5, title:'TRX', desc:'TRX', icon:require('@/assets/img/coins/TRX.svg'), active:false},
            ],
            selectedCoinData:[],
            btFullList:{},
            url:{
                page:BASE_GLOB_API+'/serial/page',
                platform:BASE_GLOB_API+'/kanban/coin/platform',
            },
            page:[],
            lang: "",
            dataList:{},
            current:1,
        }
    },
    mounted(){
        
    },
    created(){
    
        this.lang = sessionStorage.getItem("Language")
        EventBus.$on('share', value => {
      // 将 A 组件传递过来的数据保存到 B 组件
            console.log("value123",value)
           if(value){
            this.fetchUserPlatform()
            this. tradeOption=[
                {text:this.$t("lang.transactionType"), value:0},
                {text:this.$t("lang.Payment"), value:1},
                {text:this.$t("lang.Payouts"), value:2},
                {text:this.$t("lang.Into"), value:3},
                {text:this.$t("lang.RollOut"), value:4}
            ]
           }

        })
        this. tradeOption=[
                {text:this.$t("lang.transactionType"), value:0},
                {text:this.$t("lang.Payment"), value:1},
                {text:this.$t("lang.Payouts"), value:2},
                {text:this.$t("lang.Into"), value:3},
                {text:this.$t("lang.RollOut"), value:4}
            ]
        this.fetchUserPlatform()
       
    },
    beforeDestroy() {
    // 移除监听事件 "share"
       EventBus.$off('share')
    },
    methods:{
        goOption(e){
            console.log("e",e)
            this.$router.push({ name: "nftHistory-m", query: { tokenID: e } });
        },
        changeTab(e){
           console.log("e",e)
           this.fetchUserPage(this.coinType,e,10)
        },
        changeType(e){
          this.queryParam.serialType =e
          this.fetchPage(e)
        },
        changeDro(e){
          this.coinType =e
          this.fetchUserPage(e,1,10)
        },
        fetchPage(value){
            axiosPost(this.url.page,{query: {btPlatformId: this.coinType, serialType:value}}).then(res=>{
               if(res.code ==200){
                  this.dataList =res.result 
                  this.current =Number(res.result.current);
                  this.page =res.result.records
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchUserPlatform(){
            axiosGet(this.url.platform).then(res=>{
               if(res.code ==200){
                //  this.filterOption = res.result
                //   let arrDrp =[{fullName:'eth',id:'3'},{fullName:'htl',id:'122'},{fullName:'tey',id:'222'}];
                  let arrayList =res.result,fullNameList =[]
                   arrayList.forEach(item=>{
                    if(item.enabled){
                     let are ={
                        text:item.fullName,
                        value:item.id,
                     }
                     fullNameList.push(are)
                     this.filterOption = fullNameList
                    }
                   });
                   this.coinType = this.filterOption[0].value
                   
                  if(this.$route.params.flowType){
                     this.fetchUserPage(this.filterOption[0].value,1,10)
                   }else if(this.$route.params.thirdFlowNo){
                    this.fetchUserTeam(this.filterOption[0].value,1,10)
                   }else{
                    this.fetchUserPage(this.filterOption[0].value,1,10)
                   }
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchUserTeam(value,pageNo,pageSize){
            let  third = 'allot' +':' + this.$route.params.thirdFlowNo
            let params = {
                current:pageNo,
                size:pageSize,
                query: {
                  btPlatformId: value,
                  thirdFlowNo:third
                }

            }
        
            axiosPost(this.url.page,params).then(res=>{
               if(res.code ==200){
                  this.dataList =res.result 
                  this.current =Number(res.result.current);
                  this.page =res.result.records 
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        fetchUserPage(value,pageNo,pageSize){
            let params = {
                current:pageNo,
                size:pageSize,
                query: {
                  btPlatformId: value,
                  flowType:this.$route.params.flowType
                }

            }
        
            axiosPost(this.url.page,params).then(res=>{
               if(res.code ==200){
                  this.dataList =res.result 
                  this.current =Number(res.result.current);
                  this.page =res.result.records 
               }else{
                    this.$message.error(res.message);
                }
            })
           
        }, 
        handleCopy(content){
            this.$copyText(content).then(res=>{
                Toast.success("复制成功")
            },
            err => {
                Toast.fail("复制失败")
            })
        },
        selectCoin(obj, index){
            this.coinsDataSource.forEach(item=>{
                item.active = false;
            });
            obj.active = true;
            this.selectedCoinData = obj;
        },
        selectCoinSubmit(){
            
        },
        showDatePicker(type){
            this.typePicker = type;
            this.showPicker = true;
        },
        confirmDatetimePicker(e){
            console.log("confirmDatetimePicker e:", e)
            let value = moment(e).format("YYYY-MM-DD HH:mm:ss");
            if(this.typePicker == 0){
                this.queryParam.createTimeStart = value;
            }else{
                this.queryParam.createTimeEnd = value;
            }
            this.$refs.item.toggle(true);
            this.showPicker = false;
        }
    }
}
</script>
<style lang="less" scoped>
.m-module {
    .section {
        .page-title {
            font-size:20px; font-weight:600; padding:30px 15px;
        }
        .body {
            .filter-container {
                .filter-form-item {
                    .van-cell__title {width:90px; min-width:90px;}
                }
            }
            .list-wrapper {
                margin-top:1px;
                .tab{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 0;
                    margin: 0 20px;
                }
                .item {
                    .item-hd {
                        display:flex; justify-content: space-between; align-items: center; padding:0 0 3px 0;margin: 0 20px;
                        .item-title {
                            font-size:16px; font-weight:600;
                        }
                        .item-val {
                            font-size:10px;
                        }
                    }
                    .item-tradeid, .item-time {
                        font-size:12px; color:#999; line-height:1.37;
                    }
                }
            }
        }
    }
}
.steps {
    display:flex; justify-content: space-between; align-items: center;
    .step-item {
        position:relative; flex:1;
        .step-item-line {
            height:2px; background:#c0c4cc; position:absolute; left:0; right:0; top:10px;
        }
        .step-item-icon {
            width:20px; height:20px; font-size:12px; margin:0 auto; border-radius:50%; border:#c0c4cc 2px solid; display:flex; justify-content: center; align-items: center; background:#fff; color:#c0c4cc; position:relative; z-index:1;
            .el-icon-check {
                font-weight:700;
            }
        }
        .step-item-text {
            font-weight:600; padding:8px 0 10px; color:#c0c4cc; text-align: center;
        }
        &:first-child {
            .step-item-line {left:50%;}
        }
        &:last-child {
            .step-item-line {right:50%;}
        }
        &.active{
            .step-item-icon {border-color:#333; color:#333;}
            .step-item-text {color:#333;}
        }
         &.done {
            .step-item-icon {color:#fff; background:#44ac30; border-color:#44ac30;}
            .step-item-text {color:#44ac30;}
        }
    }
}
.popup-mod {
    height:100%; position:relative; overflow: hidden;
    .hd {
        padding:16px 16px;
        .title {
            font-size:16px; font-weight:700;
        }
    }
    .van-divider {margin:0;}
    .bd {
        position:absolute; top:52px; left:0; right:0; bottom:54px; overflow:auto;
        .coins {
            padding:10px;
            &>li {
                border-bottom:#e6e6e6 1px solid;
                .coin {
                    display:flex; justify-content: space-between; align-items: center; padding:10px 15px;
                    .item-icon {
                        width:32px; height:32px; min-width:32px;
                        .img {width:100%; height:100%; border-radius:50%;}
                    }
                    .item-info {
                        padding:0 10px; flex:1; text-align:left;
                        .item-title {
                            font-weight:600; color:#333;
                        }
                        .item-desc {
                            color:#999; font-size:12px;
                        }
                    }
                    .item-status {
                        display:flex; justify-content: flex-end; align-items: center;
                        .icon {display:none;}
                    }
                }
                &.active {
                    .coin {
                        background:#e8eafc; color:#4857f5; border-radius:4px;
                        .item-status {
                            .icon {color:#44ac30; display:block;}
                        }
                    }
                }
            }
        }
        .networks {
            &>li {
                padding:3px 10px;
                .item {
                     border-radius:4px; display:flex; justify-content: space-between; align-items: center; padding:10px;
                    .item-info {
                        flex:1; color:#333;
                        .item-title {
                            font-size:16px; font-weight:600;
                        }
                        .item-desc {
                            opacity: .5;
                        }
                    }
                    .item-status {
                        display:flex; justify-content: center; align-items: center;
                        .icon {display:none;}
                    }
                }
                &.active {
                    .item {
                        background:#e8eafc; color:#4857f5;
                        .item-info {
                            color:#4857f5;
                        }
                        .item-status {
                            .icon {display:block;}
                        }
                    }
                }
            }
        }
    }
    .ft {
        position:absolute; z-index:1; left:0; bottom:0; right:0; height:54px;
        .form-action {
            padding:5px 16px;
        }
    }
}
.tabbar-placeholder {
    height:54px;
}
.tabbar {
    position:fixed; left:0; bottom:0; right:0; height:54px; background:#fff; box-shadow:0 0 8px rgba(0,0,0,.08);
    .tabbar-inner {
        padding:5px 15px;
        &.u-flex {
            display:flex; justify-content: space-between; align-items: center;
            .u-flex-item {
                &:first-child {margin-right:7px;}
                &:last-child {margin-left:8px;}
            }
            .u-flex-1 {
                flex:1;
            }
            .u-flex-2 {
                flex:2;
            }
        }
    }
}
</style>